import React from "react";
import {Shortcuts} from "../../../services/shortcuts/Shortcuts";
import {Stack} from "@mui/material";
import {ShortcutCard} from "./ShortcutCard";
import {ShortcutCardAdd} from "./ShortcutCardAdd";

export interface ShortcutListProps {
	readonly reloadSource: boolean;
	readonly setLoadPage: React.Dispatch<React.SetStateAction<boolean>>;
	readonly setReloadSource: React.Dispatch<React.SetStateAction<boolean>>;
	readonly shortcuts?: Shortcuts.Shortcut[];
}

export function ShortcutList(props: ShortcutListProps) {
	const reloadSource = props.reloadSource;
	const setLoadPage = props.setLoadPage;
	const setReloadSource = props.setReloadSource;
	const shortcuts = props.shortcuts ?? [];
	
	return <Stack
		style={{
			display: "grid",
			gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
			gap: "24px",
		}}
	>
		{
			shortcuts.map((shortcut, index) =>
				<ShortcutCard
					key={index}
					shortcut={shortcut}
				/>
			)
		}
		
		<ShortcutCardAdd
			reloadSource={reloadSource}
			setLoadPage={setLoadPage}
			setReloadSource={setReloadSource}
		/>
	</Stack>;
}
