import {gql} from "@apollo/client";

export const queryAllShortcuts = gql`
	query AllShortcuts {
		allShortcuts {
			shortcuts {
				description
				disabled
				displayName
				icon
				name
				required
				type
				url
			}
		}
	}
`;

export const queryDefaultShortcutSets = gql`
	query DefaultShortcutSets {
		defaultShortcutSets {
			pageInfo {
				startCursor
				hasPreviousPage
				endCursor
				hasNextPage
			}
			
			nodes {
				id
				creationDateTime
				lastModifiedBy
				lastModifiedDateTime
				version
				
				applicableToOwnerType
				krn
				name
				
				defaultShortcuts {
					description
					disabled
					displayName
					icon
					name
					required
					type
					url
				}
			}
		}
	}
`;

export const queryDefaultShortcutSetForOwnerType = gql`
	query DefaultShortcutSetForOwnerType {
		defaultShortcutSetForOwnerType {
			id
			
			creationDateTime
			lastModifiedBy
			lastModifiedDateTime
			version
			
			applicableToOwnerType
			krn
			name
			
			defaultShortcuts {
				description
				disabled
				displayName
				icon
				name
				required
				type
				url
			}
		}
	}
`;

export const queryUserShortcutSet = gql`
	query UserShortcutSet {
		userShortcutSet {
			id
			
			creationDateTime
			lastModifiedBy
			lastModifiedDateTime
			version
			
			krn
			userKrn
			
			userShortcuts {
				description
				disabled
				displayName
				icon
				name
				required
				type
				url
			}
		}
	}
`;

export const mutationCreateDefaultShortcutSet = gql`
	mutation CreateDefaultShortcutSet(
		$input: CreateDefaultShortcutSetInput!
	) {
		createDefaultShortcutSet(
			input: $input
		) {
			defaultShortcutSet {
				id
				creationDateTime
				lastModifiedBy
				lastModifiedDateTime
				version
				
				defaultShortcuts {
					description
					disabled
					displayName
					icon
					name
					required
					type
					url
				}
				krn
				name
			}
			
			errors {
				... on IGraphQlErrorBase {
					type
					message
				}
				... on InvalidInputError {
					fieldName
					message
					reason
					type
				}
				... on NotAuthenticatedError {
					message
					reason
					type
				}
				... on NotAuthorizedError {
					user
					action
					resource
					reason
				}
			}
		}
	}
`;

export const mutationUpdateDefaultShortcutSet = gql`
	mutation UpdateDefaultShortcutSet(
		$input: UpdateDefaultShortcutSetInput!
	) {
		updateDefaultShortcutSet(
			input: $input
		) {
			defaultShortcutSet {
				id
				creationDateTime
				lastModifiedBy
				lastModifiedDateTime
				version
				
				applicableToOwnerType
				krn
				name
				
				defaultShortcuts {
					description
					disabled
					displayName
					icon
					name
					required
					type
					url
				}
			}
			
			errors {
				... on IGraphQlErrorBase {
					type
					message
				}
				... on InvalidInputError {
					fieldName
					message
					reason
					type
				}
				... on NotAuthenticatedError {
					message
					reason
					type
				}
				... on NotAuthorizedError {
					user
					action
					resource
					reason
				}
				... on DocumentNotFoundError {
					documentId
					message
					type
				}
			}
		}
	}
`;

export const mutationCreateUserShortcutSet = gql`
	mutation CreateUserShortcutSet(
		$input: CreateUserShortcutSetInput!
	) {
		createUserShortcutSet(
			input: $input
		) {
			userShortcutSet {
				id
				creationDateTime
				lastModifiedBy
				lastModifiedDateTime
				version
				
				userShortcuts {
					description
					disabled
					displayName
					icon
					name
					required
					type
					url
				}
				
				krn
			}
			
			errors {
				... on IGraphQlErrorBase {
					type
					message
				}
				... on InvalidInputError {
					fieldName
					message
					reason
					type
				}
				... on NotAuthenticatedError {
					message
					reason
					type
				}
				... on NotAuthorizedError {
					user
					action
					resource
					reason
				}
			}
		}
	}
`;

export const mutationEnableOrDisableShortcutForUsers = gql`
	mutation EnableOrDisableShortcutForUsers(
		$input: EnableOrDisableShortcutForUsersInput!
	) {
		enableOrDisableShortcutForUsers(
			input: $input
		) {
			errors {
				... on IGraphQlErrorBase {
					type
					message
				}
				... on InvalidInputError {
					fieldName
					message
					reason
					type
				}
				... on NotAuthenticatedError {
					message
					reason
					type
				}
				... on NotAuthorizedError {
					user
					action
					resource
					reason
				}
			}
		}
	}
`;

export const mutationUpdateUserShortcutSet = gql`
	mutation UpdateUserShortcutSet(
		$input: UpdateUserShortcutSetInput!
	) {
		updateUserShortcutSet(
			input: $input
		) {
			userShortcutSet {
				id
				creationDateTime
				lastModifiedBy
				lastModifiedDateTime
				version
				
				krn
				userKrn
				
				userShortcuts {
					description
					disabled
					displayName
					icon
					name
					required
					type
					url
				}
			}
			
			errors {
				... on IGraphQlErrorBase {
					type
					message
				}
				... on InvalidInputError {
					fieldName
					message
					reason
					type
				}
				... on NotAuthenticatedError {
					message
					reason
					type
				}
				... on NotAuthorizedError {
					user
					action
					resource
					reason
				}
				... on DocumentNotFoundError {
					documentId
					message
					type
				}
			}
		}
	}
`;
