import React, {useState} from "react";
import {fullHeight, SbTypography} from "@surebase/shared-component-library";
import {Alert, Stack} from "@mui/material";
import {ShortcutsDataRepository} from "../services/shortcuts/ShortcutsDataRepository";
import {Shortcuts} from "../services/shortcuts/Shortcuts";
import Shortcut = Shortcuts.Shortcut;
import {ShortcutList} from "./shortcuts/overview/ShortcutList";
import {DotsVertical} from "./shortcuts/DotsVertical";

export function HomePage() {
	const [error, setError] = useState<string>();
	const [loadPage, setLoadPage] = useState<boolean>(true);
	const [reloadSource, setReloadSource] = useState<boolean>(false);
	
	const [allShortcuts, setAllShortcuts] = useState<Shortcut[]>([]);
	
	if (loadPage) {
		setLoadPage(false);
		
		ShortcutsDataRepository.getAllShortcuts().then(payload => {
			setAllShortcuts(payload.shortcuts);
		}).catch(() => {
			setError("Unable to retrieve all shortcuts.");
		});
	}
	
	return <Stack
		style={{
			...fullHeight,
			paddingBottom: "30px"
		}}
	>
		<Stack
			gap={3}
			style={{
				...fullHeight
			}}
		>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
			>
				<SbTypography variant={"header2"}>
					Favorites
				</SbTypography>
				
				<DotsVertical
					reloadSource={reloadSource}
					setLoadPage={setLoadPage}
					setReloadSource={setReloadSource}
				/>
			</Stack>
			
			{error &&
				<Alert severity={"error"}>
					<SbTypography>
						{error}
					</SbTypography>
				</Alert>
			}
			
			<ShortcutList
				reloadSource={reloadSource}
				setLoadPage={setLoadPage}
				setReloadSource={setReloadSource}
				shortcuts={allShortcuts}
			/>
		</Stack>
	</Stack>;
}
