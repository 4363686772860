import {Autocomplete, ListSubheader, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import * as Icons from "@surebase/shared-component-library";
import {ListChildComponentProps, VariableSizeList} from "react-window";
import { SbIconProps } from "@surebase/shared-component-library";

const icons = Object.entries(Icons).filter(([key]) => key.endsWith("Icon"));
const iconKeys = icons.map(([key]) => key);
const iconProps: SbIconProps = {
	color: "primary",
	width: 20,
};

export interface IconSelectorProps {
	readonly value?: string | null;
	readonly onChange: (value: string | null) => void;
}

export function IconSelector(props: IconSelectorProps) {
	return <Autocomplete<string>
		value={props.value}
		options={iconKeys}
		renderInput={(params) => <TextField
			{...params}
			label={"Icon"}
		/>}
		onChange={(_, item) => props.onChange(item)}
		autoHighlight
		selectOnFocus
		autoFocus
		ListboxComponent={ListboxComponent}
		renderOption={(props, option, state) =>
			[props, option, state.index] as React.ReactNode
		}
	/>;
}

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
	const {data, index, style} = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: (style.top as number) + LISTBOX_PADDING,
	};
	
	// eslint-disable-next-line no-prototype-builtins
	if (dataSet.hasOwnProperty("group")) {
		return (
			<ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
				{dataSet.group} (2)
			</ListSubheader>
		);
	}
	
	const {key, ...optionProps} = dataSet[0];
	
	return (
		<Typography key={key} component="li" {...optionProps} noWrap style={inlineStyle}>
			{(Icons as any)[dataSet[1]](iconProps)} {dataSet[1]}
		</Typography>
	);
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
	const ref = React.useRef<VariableSizeList>(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

const ListboxComponent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
	const {children, ...other} = props;
	const itemData: React.ReactElement[] = [];
	(children as React.ReactElement[]).forEach(
		(item: React.ReactElement & { children?: React.ReactElement[] }) => {
			itemData.push(item);
			itemData.push(...(item.children || []));
		},
	);
	
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
		noSsr: true,
	});
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;
	
	const getChildSize = (child: React.ReactElement) => {
		// eslint-disable-next-line no-prototype-builtins
		if (child.hasOwnProperty("group")) {
			return 48;
		}
		
		return itemSize;
	};
	
	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};
	
	const gridRef = useResetCache(itemCount);
	
	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index: number) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});
