import React, {useState} from "react";
import {Shortcuts} from "../../../services/shortcuts/Shortcuts";
import {Alert, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Stack} from "@mui/material";
import {SbProcessButton, SbTextField, SbTypography, XCloseIcon} from "@surebase/shared-component-library";
import {IconSelector} from "../IconSelector";
import {ShortcutsDataRepository} from "../../../services/shortcuts/ShortcutsDataRepository";

export interface ShortcutCardDefaultAddDialogProps {
	readonly krn: string;
	readonly onClose: () => void;
	readonly open: boolean;
	readonly ownerType: string;
	readonly setLoadPage?: React.Dispatch<React.SetStateAction<boolean>>;
	readonly shortcuts?: Shortcuts.Shortcut[];
}

export function ShortcutCardDefaultAddDialog(props: ShortcutCardDefaultAddDialogProps) {
	const [error, setError] = useState<string>();
	const [nameInputError, setNameInputError] = useState<string>();
	const [newSetNameInputError, setNewSetNameInputError] = useState<string>();
	
	const krn = props.krn;
	const onClose = props.onClose;
	const open = props.open;
	const ownerType = props.ownerType as Shortcuts.SureBaseResourceOwnerType;
	const setLoadPage = props.setLoadPage;
	const shortcuts = props.shortcuts ?? [];
	
	const defaultShortcutSetExists = props.shortcuts !== undefined;
	
	const [newSetName, setNewSetName] = useState<string>("");
	
	const [newShortcut, setNewShortcut] = useState<Shortcuts.Shortcut>({
		description: null,
		displayName: null,
		icon: null,
		name: "",
		type: "NAVIGATION",
		url: null,
		disabled: false,
		required: false
	});
	
	async function updateDefaultShortcutSet() {
		if (!validInput())
			return;
		
		if (!defaultShortcutSetExists && doesShortcutWithSameNameAlreadyExist()) {
			setError("A shortcut with the same name already exists.");
			
			return;
		}
		
		shortcuts.push(newShortcut);
		
		const response = !defaultShortcutSetExists
			? await ShortcutsDataRepository.createDefaultShortcutSet({applicableToOwnerType: ownerType, defaultShortcuts: shortcuts, name: newSetName})
			: await ShortcutsDataRepository.updateDefaultShortcutSet({krn, defaultShortcuts: shortcuts});
		
		if (response.errors != null && response.errors.length > 0)
			throw new Error(response.errors[0].message);
		
		if (setLoadPage)
			setLoadPage(true);
		
		onClose();
	}
	
	function validInput() {
		const isNameInputValid = newShortcut.name !== "";
		const isNewSetNameInputValid = !defaultShortcutSetExists ? newSetName !== "" : true;
		
		setNameInputError(isNameInputValid ? undefined : "This field is required");
		setNewSetNameInputError(isNewSetNameInputValid ? undefined : "This field is required");
		
		return isNameInputValid && isNewSetNameInputValid;
	}
	
	function doesShortcutWithSameNameAlreadyExist() {
		return !!shortcuts.find((shortcut) => shortcut.name === newShortcut.name);
	}
	
	return <Dialog
		fullWidth
		maxWidth="sm"
		open={open}
		onClose={onClose}
	>
		<DialogTitle>
			<SbTypography>
				{ !defaultShortcutSetExists
					? "Create new default-shortcut set"
					: "Add new default-shortcut"
				}
			</SbTypography>
		</DialogTitle>
		
		<IconButton
			aria-label="close"
			onClick={onClose}
			sx={(theme) => ({
				position: "absolute",
				right: 8,
				top: 8,
				color: theme.palette.grey[500],
			})}
		>
			<XCloseIcon style={{color: "#000000"}}/>
		</IconButton>
		
		<DialogContent
			style={{minWidth: "400px"}}
		>
			<Stack gap={1}>
				{!defaultShortcutSetExists &&
					<SbTextField
						autoFocus={!defaultShortcutSetExists}
						label={<SbTypography>Set name *</SbTypography>}
						name={"setName"}
						onChange={setNewSetName}
						type={"text"}
						value={newSetName}
						variant={"outlined"}
						error={newSetNameInputError}
					/>
				}
				
				<SbTextField
					autoFocus={defaultShortcutSetExists}
					label={<SbTypography>Description</SbTypography>}
					name={"description"}
					onChange={value => setNewShortcut(shortcut => ({
						...shortcut,
						description: value !== "" ? value : null
					}))}
					type={"text"}
					value={newShortcut.description ?? undefined}
					variant={"outlined"}
				/>
				
				<SbTextField
					label={<SbTypography>Display name</SbTypography>}
					name={"displayName"}
					onChange={value => setNewShortcut(shortcut => ({
						...shortcut,
						displayName: value !== "" ? value : null
					}))}
					type={"text"}
					value={newShortcut.displayName ?? undefined}
					variant={"outlined"}
				/>
				
				<IconSelector
					onChange={value => setNewShortcut(shortcut => ({
						...shortcut,
						icon: value !== "" ? value : null
					}))}
					value={newShortcut.icon ?? undefined}
				/>
				
				<SbTextField
					label={<SbTypography>Name *</SbTypography>}
					name={"name"}
					onChange={value => setNewShortcut(shortcut => ({
						...shortcut,
						name: value
					}))}
					type={"text"}
					value={newShortcut.name ?? undefined}
					variant={"outlined"}
					error={nameInputError}
				/>
				
				<SbTextField
					label={<SbTypography>Url</SbTypography>}
					name={"url"}
					onChange={value => setNewShortcut(shortcut => ({
						...shortcut,
						url: value !== "" ? value : null
					}))}
					type={"text"}
					value={newShortcut.url ?? undefined}
					variant={"outlined"}
				/>
				
				<FormControlLabel
					control={
						<Checkbox
							onChange={event => setNewShortcut(shortcut => ({
								...shortcut,
								required: event.target.checked
							}))}
							checked={newShortcut.required}
						/>
					}
					label={
						<SbTypography>
							Required
						</SbTypography>
					}
				/>
				
				{error &&
					<Alert severity={"error"}>
						<SbTypography>{error}</SbTypography>
					</Alert>
				}
				
				<SbProcessButton
					defaultContent={
						<SbTypography style={{
							fontWeight: "600",
							fontSize: "16px",
							lineHeight: "24px"
						}}>
							Create
						</SbTypography>
					}
					startIcon={<></>}
					LoadingButtonProps={{
						size: "large",
						variant: "contained",
						style: {
							borderRadius: "8px"
						},
						onClick: updateDefaultShortcutSet
					}}
				/>
			</Stack>
		</DialogContent>
	</Dialog>;
}
