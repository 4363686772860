import {Button, Stack} from "@mui/material";
import {fullHeight, palette, PlusIcon} from "@surebase/shared-component-library";
import React, {useState} from "react";
import {Shortcuts} from "../../../services/shortcuts/Shortcuts";
import { ShortcutCardDefaultAddDialog } from "./ShortcutCardDefaultAddDialog";

const minWidth = "388px";
const maxWidth = "450px";
const height = "112px";

export interface ShortcutCardDefaultAddProps {
	readonly krn: string;
	readonly ownerType: string;
	readonly setLoadPage?: React.Dispatch<React.SetStateAction<boolean>>;
	readonly shortcuts?: Shortcuts.Shortcut[];
}

export function ShortcutCardDefaultAdd(props: ShortcutCardDefaultAddProps) {
	const [open, setOpen] = useState<boolean>(false);
	
	const krn = props.krn;
	const ownerType = props.ownerType;
	const setLoadPage = props.setLoadPage;
	const shortcuts = props.shortcuts;
	
	return <>
		<Button
			onClick={() => setOpen(true)}
			style={{
				minWidth,
				maxWidth,
				height,
				flex: "1 0 0px",
				borderRadius: "8px",
				border: "1px dashed var(--secondary-outline-border, rgba(56, 66, 80, 0.50))", // todo hardcoded color
				color: palette.passive.main
			}}
		>
			<Stack
				direction={"row"}
				alignItems={"center"}
				justifyContent={"space-around"}
				style={{
					...fullHeight
				}}
			>
				<PlusIcon
					width={24}
					color={"primary"}
				/>
			</Stack>
		</Button>
		
		<ShortcutCardDefaultAddDialog
			krn={krn}
			onClose={() => setOpen(false)}
			open={open}
			ownerType={ownerType}
			setLoadPage={setLoadPage}
			shortcuts={shortcuts}
		/>
	</>;
}
