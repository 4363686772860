import {Button, Stack} from "@mui/material";
import {fullHeight, palette, PlusIcon} from "@surebase/shared-component-library";
import React, {useState} from "react";
import { ShortcutCardAddDialog } from "./ShortcutCardAddDialog";

const minWidth = "388px";
const maxWidth = "450px";
const height = "112px";

export interface ShortcutCardAddProps {
	readonly reloadSource: boolean;
	readonly setLoadPage: React.Dispatch<React.SetStateAction<boolean>>;
	readonly setReloadSource: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ShortcutCardAdd(props: ShortcutCardAddProps) {
	const [open, setOpen] = useState<boolean>(false);
	
	const reloadSource = props.reloadSource;
	const setLoadPage = props.setLoadPage;
	const setReloadSource = props.setReloadSource;
	
	return <>
		<Button
			onClick={() => setOpen(true)}
			style={{
				minWidth,
				maxWidth,
				height,
				flex: "1 0 0px",
				borderRadius: "8px",
				border: "1px dashed var(--secondary-outline-border, rgba(56, 66, 80, 0.50))", // todo hardcoded color
				color: palette.passive.main
			}}
		>
			<Stack
				direction={"row"}
				alignItems={"center"}
				justifyContent={"space-around"}
				style={{
					...fullHeight
				}}
			>
				<PlusIcon
					width={24}
					color={"primary"}
				/>
			</Stack>
		</Button>
		
		<ShortcutCardAddDialog
			onClose={() => setOpen(!open)}
			open={open}
			reloadSource={reloadSource}
			setLoadPage={setLoadPage}
			setReloadSource={setReloadSource}
		/>
	</>;
}
