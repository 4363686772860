import {
	authorizedByPermission,
	fullSize,
	fullWidth,
	SbButton,
	SbInvoiceCard,
	SbPageHeader,
	SbTypography,
	SbTypographyRaw,
	TranslationProvider,
	useMobileView,
	useTranslation
} from "@surebase/shared-component-library";
import {permissions} from "../../global/Permissions";
import React, {useState} from "react";
import {Settings} from "../../global/Settings";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	CircularProgress,
	Divider,
	Stack,
	useTheme
} from "@mui/material";
import {grey} from "@mui/material/colors";
import {OutstandingInvoicesCard} from "./OutstandingInvoicesCard";
import {InvoicesDataRepository} from "../../services/invoices/graphql/InvoicesDataRepository";
import {Invoices} from "../../services/invoices/graphql/Invoices";
import {useParams} from "react-router-dom";
import {DossierHelper} from "../../helpers/DossierHelper";
import {Dossiers} from "../../services/documents/Dossiers";

export const InvoicesPage = authorizedByPermission(() => {
	const inMobileView = useMobileView();
	const theme = useTheme();
	const translation = useTranslation();
	const customerId = useParams()["id"] ?? "";
	const [loading, setLoading] = useState<boolean>(true);
	const [fetchPageData, setFetchPageData] = useState<boolean>(true);
	const [invoices, setInvoices] = useState<Invoices.Invoice[]>([]);
	const [openInvoices, setOpenInvoices] = useState<Invoices.Invoice[]>([]);
	const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<string[]>([]);
	const [allSelected, setAllSelected] = useState<boolean>(false);
	const [canSelectInvoices, setCanSelectInvoices] = useState<boolean>(false);
	
	if (fetchPageData && customerId) {
		setFetchPageData(false);
		InvoicesDataRepository.getInvoices(customerId).then((invoicesResponse: Invoices.Invoice[]) => {
			if (invoicesResponse.length === 0) {
				setLoading(false);
				return;
			}
			
			setInvoices(invoicesResponse);
			const length = invoicesResponse.length;
			const outstandingArray: Invoices.Invoice[] = [];
			let invoiceDocumentCount = 0;
			for (let i = 0; i < length; i++) {
				const invoice = invoicesResponse[i];
				if (invoice.amountOutstandingInvoice !== null && invoice.amountOutstandingInvoice > 0) {
					outstandingArray.push(invoice);
				}
				
				if (invoice.documentRef.length > 0)
					invoiceDocumentCount++;
			}
			
			setOpenInvoices(outstandingArray);
			setCanSelectInvoices(invoiceDocumentCount > 0);
			setLoading(false);
		});
	}
	
	if (loading) {
		return <Box sx={{display: "flex", width: "100%", height: "100%"}}>
			<CircularProgress style={{margin: "auto"}}/>
		</Box>;
	}
	
	const handleInvoiceSelection = (itemId: string, isChecked: boolean) => {
		const selectedInvoice = invoices.filter(invoice => invoice.id === itemId);
		if (!selectedInvoice || selectedInvoice.length === 0 || selectedInvoice[0].documentRef.length === 0)
			return;
		
		let updatedSelection = [];
		if (isChecked) {
			updatedSelection = [...selectedInvoiceIds, itemId];
		} else {
			const index = selectedInvoiceIds.indexOf(itemId);
			if (index === -1)
				return;
			
			updatedSelection = [
				...selectedInvoiceIds.slice(0, index),
				...selectedInvoiceIds.slice(index + 1)
			];
		}
		
		setSelectedInvoiceIds(updatedSelection);
		if (invoices.length > 0) {
			const selectableLength = invoices.filter(invoice => invoice.documentRef.length > 0).length;
			setAllSelected(updatedSelection.length === selectableLength);
		}
	};
	
	const handleAllItemsSelected = () => {
		let selections: string[] = [];
		if (!allSelected) {
			selections = invoices.map(x => x.id);
		}
		
		setAllSelected(!allSelected);
		setSelectedInvoiceIds(selections);
	};
	
	const handlePayInvoicesClick = (invoiceIds: string[]) => {
		//todo update routing when contract page is created
		console.log("pay invoices", invoiceIds);
	};
	
	const getDownloadText = () => {
		if (selectedInvoiceIds.length === 1) {
			return translation("Download %count% invoice", {"count": "1"});
		}
		
		return translation("Download %count% invoices", {"count": selectedInvoiceIds.length});
	};
	
	const getPadding = () => {
		if (inMobileView)
			return "8px";
		
		if (!canSelectInvoices)
			return "8px 16px 8px 16px";
		
		return "8px 16px 8px 8px";
	};
	
	const downloadSelectedInvoices = () => {
		if (!canSelectInvoices || selectedInvoiceIds.length === 0)
			return;
		
		DossierHelper.downloadDossiers(selectedInvoiceIds).then((dossierResponses: (Dossiers.DownloadFile | null)[]) => {
			const dossierResponseLength = dossierResponses.length;
			for (let i = 0; i < dossierResponseLength; i++) {
				const dossierResponse = dossierResponses[i];
				if (!dossierResponse || dossierResponse.base64File !== "")
					return;
				
				DossierHelper.parseResponseAndDownload(dossierResponse);
			}
		});
	};
	
	return <TranslationProvider name={Settings.languageModule}>
		<Stack gap={2} style={{...fullWidth}}>
			<Stack>
				<SbPageHeader pageName={"My invoices"} overviewRoute={"/customer-portal"} disableBreadcrumbs={true}/>
			</Stack>
			
			<Stack gap={4} direction={inMobileView ? "column" : "row"} style={{...fullWidth}}>
				<Stack gap={3} style={{...fullWidth}}>
					<Card variant={"outlined"}>
						<CardHeader title={<SbTypography variant={"cardButtonTitle1"}>All invoices</SbTypography>}
							style={{backgroundColor: "#FFFFFF", padding: "16px", gap: "24px"}}/>
						<Divider color={grey[300]}/>
						<CardContent>
							<Stack gap={2}>
								<SbTypography variant={"cardButtonContent2"} color={theme.palette.text.secondary}>
									Below is a summary of all your payments. Click on a message to download it
								</SbTypography>
								{invoices.map((invoice, index) => (
									<SbInvoiceCard key={index}
										invoice={invoice}
										isSelectable={canSelectInvoices}
										disableSelection={invoice.documentRef.length === 0}
										isSelected={selectedInvoiceIds.indexOf(invoice.id) > -1}
										onSelectionChange={handleInvoiceSelection}
										showStatus={true}
										cardContainerStyle={{height: "auto", width: "100%", borderColor: grey[300]}}
										buttonStyle={{padding: getPadding()}}
									/>
								))}
							</Stack>
						</CardContent>
					</Card>
					{(invoices.length !== 0 && canSelectInvoices) && (
						<Stack>
							<Card variant={"outlined"} style={{...fullSize, padding: "16px"}}>
								<Stack direction={inMobileView ? "column" : "row"}
									alignItems={inMobileView ? "flex-start" : "center"} flexGrow={1}
									gap={inMobileView ? "8px" : 2} style={{...fullSize}}>
									<Stack gap={inMobileView ? "4px" : 2} direction={"row"} alignItems={"center"}>
										<Checkbox onClick={handleAllItemsSelected} checked={allSelected}/>
										<Stack direction={"row"}>
											<SbTypography variant={"cardButton1"} color={theme.palette.text.primary}>
												Select all items
											</SbTypography>
										</Stack>
									</Stack>
									{selectedInvoiceIds.length > 0 && (
										<SbButton ButtonProps={{
											size: "large",
											variant: "contained",
											style: {
												padding: "10px 18px",
												borderRadius: "8px"
											},
											onClick: downloadSelectedInvoices
										}}>
											<SbTypographyRaw variant={"buttonLarge"}>
												{getDownloadText()}
											</SbTypographyRaw>
										</SbButton>
									)}
								</Stack>
							</Card>
						</Stack>
					)}
				</Stack>
				{openInvoices.length !== 0 && (
					<Stack>
						<OutstandingInvoicesCard width={"100%"} invoices={openInvoices}
							onPayClick={handlePayInvoicesClick}/>
					</Stack>
				)}
			</Stack>
		</Stack>
	</TranslationProvider>;
}, [permissions.invoicesRead]);
