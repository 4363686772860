import {graphQlServices} from "../../GraphQlServices";
import {Invoices} from "./Invoices";
import {GET_INVOICES} from "./InvoicesQueries";
import {ApolloQueryResult} from "@apollo/client";

export class InvoicesDataRepository {
	
	static async getInvoices(customerId: string): Promise<Invoices.Invoice[]> {
		let allInvoices: Invoices.Invoice[] = [];
		let hasNextPage = true;
		let afterCursor: string | null = null;
		while (hasNextPage) {
			const response: ApolloQueryResult<Invoices.Queries> = await graphQlServices.invoicesService.query<Invoices.Queries>(
				{
					query: GET_INVOICES,
					variables: {
						first: 50,
						after: afterCursor,
						where: this.getInvoicesDateFilter(customerId),
						order: [{bookingDate: "DESC"}]
					},
					fetchPolicy: "no-cache"
				}
			);
			
			if (response.error)
				throw response.error;
			
			const invoices = response.data?.invoices?.nodes ?? [];
			allInvoices = allInvoices.concat(invoices);
			hasNextPage = response.data?.invoices?.pageInfo?.hasNextPage ?? false;
			afterCursor = response.data?.invoices?.pageInfo?.endCursor ?? null;
		}
		
		return allInvoices;
	}
	
	private static getInvoicesDateFilter(customerId: string) {
		const pastYear = new Date();
		pastYear.setMonth(pastYear.getMonth() - 12);
		const pastTwoYears = new Date();
		pastTwoYears.setMonth(pastTwoYears.getMonth() - 24);
		
		return {
			and: [
				{
					party: {
						some: {
							and: [
								{refKey: {eq: customerId}},
								{entityType: {eq: "customer"}}
							]
						}
					}
				},
				{
					or: [
						{
							and: [
								{
									paymentTermInMonths: {lte: 12}
								},
								{
									bookingDate: {gte: pastYear.toISOString()}
								}
							]
						},
						{
							and: [
								{
									paymentTermInMonths: {
										gt: 12
									}
								},
								{
									bookingDate: {gte: pastTwoYears.toISOString()}
								}
							]
						}
					]
				}
			]
		};
	}
}
