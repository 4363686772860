import {Shortcuts} from "../../../services/shortcuts/Shortcuts";
import React, {useState} from "react";
import {Card, IconButton, Stack} from "@mui/material";
import {fullSize, Pencil01Icon, SbTypography, SbTypographyRaw} from "@surebase/shared-component-library";
import {ShortcutCardDefaultEditDialog} from "./ShortcutCardDefaultEditDialog";
import {baseStyle} from "../BaseStyle";
import { ShortcutIcon } from "../ShortcutIcon";

export interface ShortcutCardDefaultEditProps {
	readonly setKrn: string;
	readonly setLoadPage?: React.Dispatch<React.SetStateAction<boolean>>;
	readonly shortcut: Shortcuts.Shortcut;
	readonly shortcuts: Shortcuts.Shortcut[];
}

export function ShortcutCardDefaultEdit(props: ShortcutCardDefaultEditProps) {
	const [open, setOpen] = useState(false);
	
	const [actionDisplay, setActionDisplay] = useState({ display: "none" });
	
	const setKrn = props.setKrn;
	const setLoadPage = props.setLoadPage;
	const shortcut = props.shortcut;
	const shortcuts = props.shortcuts;
	
	return <Card
		style={baseStyle}
		variant={"outlined"}
	>
		<Stack
			direction={"column"}
			alignItems={"center"}
			style={{
				...fullSize,
				borderRadius: "inherit",
				padding: "12px 24px",
				gap: "8px",
				justifyContent: "center",
				flexDirection: "column",
				position: "relative"
			}}
			onMouseEnter={_ => {
				setActionDisplay({display: "block"});
			}}
			onMouseLeave={_ => {
				setActionDisplay({display: "none"});
			}}
		>
			<IconButton
				style={{
					position: "absolute",
					top: 0,
					right: 0,
					...actionDisplay
				}}
				onClick={() => setOpen(true)}
			>
				<Pencil01Icon />
			</IconButton>
			
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center"
				}}
			>
				<ShortcutIcon data={shortcut} />
			</div>
			
			<SbTypographyRaw
				variant={"cardButtonHeader2"}
			>
				{shortcut.displayName ?? shortcut.name}
			</SbTypographyRaw>
			
			<SbTypography
				variant={"cardButtonContent2"}
				color={theme => theme.palette.text.primary}
			>
				{shortcut.description}
			</SbTypography>
		</Stack>
		
		<ShortcutCardDefaultEditDialog
			krn={setKrn}
			onClose={() => setOpen(false)}
			open={open}
			setLoadPage={setLoadPage}
			shortcut={shortcut}
			shortcuts={shortcuts}
		/>
	</Card>;
}
