import {Shortcuts} from "./Shortcuts";
import {
	mutationCreateDefaultShortcutSet,
	mutationCreateUserShortcutSet, mutationEnableOrDisableShortcutForUsers,
	mutationUpdateDefaultShortcutSet,
	mutationUpdateUserShortcutSet,
	queryAllShortcuts,
	queryDefaultShortcutSetForOwnerType, queryDefaultShortcutSets,
	queryUserShortcutSet
} from "./ShortcustQueries";
import CreateDefaultShortcutSetInput = Shortcuts.CreateDefaultShortcutSetInput;
import CreateUserShortcutSetInput = Shortcuts.CreateUserShortcutSetInput;
import UpdateDefaultShortcutSetInput = Shortcuts.UpdateDefaultShortcutSetInput;
import UpdateUserShortcutSetInput = Shortcuts.UpdateUserShortcutSetInput;
import {graphQlServices} from "../GraphQlServices";
import EnableOrDisableShortcutForUsersInput = Shortcuts.EnableOrDisableShortcutForUsersInput;

export class ShortcutsDataRepository {
	static async getAllShortcuts(): Promise<Shortcuts.GetAllShortcutsPayload> {
		const response = await graphQlServices.shortcutsService.query<Shortcuts.Queries>({
			query: queryAllShortcuts,
			fetchPolicy: "no-cache",
		});

		if (response.error)
			throw response.error;

		return response.data.allShortcuts;
	}

	static async retrieveDefaultShortcutSets(): Promise<Shortcuts.DefaultShortcutSet[] | null> {
		const response = await graphQlServices.shortcutsService.query<Shortcuts.Queries>({
			query: queryDefaultShortcutSets,
			fetchPolicy: "no-cache",
		});
		
		if (response.errors)
			throw response.errors;
		
		if (response.data === undefined || response.data === null)
			throw new Error("Data not found.");
		
		return response.data.defaultShortcutSets?.nodes ?? null;
	}
	
	static async retrieveDefaultShortcutSetForOwnerType(): Promise<Shortcuts.DefaultShortcutSet | null> {
		const response = await graphQlServices.shortcutsService.query<Shortcuts.Queries>({
			query: queryDefaultShortcutSetForOwnerType,
			fetchPolicy: "no-cache",
		});
		
		if (response.errors)
			throw response.errors;
		
		if (response.data === undefined || response.data === null)
			throw new Error("Data not found.");
		
		return response.data.defaultShortcutSetForOwnerType;
	}
	
	static async retrieveUserShortcutSet(): Promise<Shortcuts.UserShortcutSet | null> {
		const response = await graphQlServices.shortcutsService.query<Shortcuts.Queries>({
			query: queryUserShortcutSet,
			fetchPolicy: "no-cache",
		});
		
		if (response.errors)
			throw response.errors;
		
		if (response.data === undefined || response.data === null)
			throw new Error("Data not found.");
		
		return response.data.userShortcutSet;
	}
	
	static async createDefaultShortcutSet(input: CreateDefaultShortcutSetInput): Promise<Shortcuts.CreateDefaultShortcutSetPayload> {
		const response = await graphQlServices.shortcutsService.mutate<Shortcuts.Queries>({
			mutation: mutationCreateDefaultShortcutSet,
			variables: {
				input: input
			},
			fetchPolicy: "no-cache",
		});
		
		if (response.errors)
			throw response.errors;
		
		if (response.data === undefined || response.data === null)
			throw new Error("Data not found.");
		
		return response.data.createDefaultShortcutSet;
	}
	
	static async createUserShortcutSet(input: CreateUserShortcutSetInput): Promise<Shortcuts.CreateUserShortcutSetPayload> {
		const response = await graphQlServices.shortcutsService.mutate<Shortcuts.Queries>({
			mutation: mutationCreateUserShortcutSet,
			variables: {
				input: input
			},
			fetchPolicy: "no-cache",
		});
		
		if (response.errors)
			throw response.errors;
		
		if (response.data === undefined || response.data === null)
			throw new Error("Data not found.");
		
		return response.data.createUserShortcutSet;
	}
	
	static async updateDefaultShortcutSet(input: UpdateDefaultShortcutSetInput): Promise<Shortcuts.UpdateDefaultShortcutSetPayload> {
		const response = await graphQlServices.shortcutsService.mutate<Shortcuts.Queries>({
			mutation: mutationUpdateDefaultShortcutSet,
			variables: {
				input: input
			},
			fetchPolicy: "no-cache",
		});
		
		if (response.errors)
			throw response.errors;
		
		if (response.data === undefined || response.data === null)
			throw new Error("Data not found.");
		
		return response.data.updateDefaultShortcutSet;
	}
	
	static async updateUserShortcutSet(input: UpdateUserShortcutSetInput): Promise<Shortcuts.UpdateUserShortcutSetPayload> {
		const response = await graphQlServices.shortcutsService.mutate<Shortcuts.Queries>({
			mutation: mutationUpdateUserShortcutSet,
			variables: {
				input: input
			},
			fetchPolicy: "no-cache",
		});
		
		if (response.errors)
			throw response.errors;
		
		if (response.data === undefined || response.data === null)
			throw new Error("Data not found.");
		
		return response.data.updateUserShortcutSet;
	}
	
	static async enableOrDisableShortcutForUsers(input: EnableOrDisableShortcutForUsersInput): Promise<Shortcuts.EnableOrDisableShortcutForUsersPayload> {
		const response = await graphQlServices.shortcutsService.mutate<Shortcuts.Queries>({
			mutation: mutationEnableOrDisableShortcutForUsers,
			variables: {
				input: input
			},
			fetchPolicy: "no-cache"
		});
		
		if (response.errors)
			throw response.errors;
		
		if (response.data === undefined || response.data === null)
			throw new Error("data not found");
		
		return response.data.enableOrDisableShortcutForUsers;
	}
}
