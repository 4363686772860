import {ApolloClient} from "@apollo/client";
import {createApolloClient, sharedConfiguration} from "@surebase/shared-component-library";

class GraphQlServices {
	private _customerServices: ApolloClient<any> | null = null;
	private _dossierServices: ApolloClient<any> | null = null;
	private _invoicesServices: ApolloClient<any> | null = null;
	private _shortcutsServices: ApolloClient<any> | null = null;
	
	get customerServices(): ApolloClient<any> {
		return this._customerServices || (this._customerServices = createApolloClient(
			`${sharedConfiguration.backendHost}/customers/graphql`
		));
	}
	
	get dossierServices(): ApolloClient<any> {
		return this._dossierServices || (this._dossierServices = createApolloClient(
			`${sharedConfiguration.backendHost}/dossiers/graphql`
		));
	}
	
	get invoicesService(): ApolloClient<any> {
		return this._invoicesServices || (this._customerServices = createApolloClient(
			`${sharedConfiguration.backendHost}/invoices/graphql`
		));
	}
	
	get shortcutsService(): ApolloClient<any> {
		return this._shortcutsServices || (this._shortcutsServices = createApolloClient(
			`${sharedConfiguration.backendHost}/shortcuts/graphql`
		));
	}
}

export const graphQlServices = new GraphQlServices();
