import {Shortcuts} from "../../services/shortcuts/Shortcuts";
import {SbIconProps} from "@surebase/shared-component-library";
import * as Icons from "@surebase/shared-component-library";

export interface ShortcutIconProps {
	data: Shortcuts.Shortcut;
}

export function ShortcutIcon(props: ShortcutIconProps) {
	const data = props.data;
	const icon = data.icon ?? "";
	
	const iconProps: SbIconProps = {
		color: "primary",
		width: 24,
	};
	
	return getIconByName(icon)(iconProps);
}

function getIconByName(name: string, defaultName?: string): any {
	const func = (Icons as any)[name];
	
	if (func)
		return func;
	
	return (Icons as any)[defaultName || "FileQuestion01Icon"];
}
