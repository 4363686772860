import {CSSProperties} from "react";

const minWidth = "388px";
const maxWidth = "450px";
const height = "112px";

export const baseStyle: CSSProperties = {
	borderRadius: "8px",
	minWidth,
	maxWidth,
	height,
};
