import React, {useState} from "react";
import {DotsVerticalIcon, SbButton, SbIconButton} from "@surebase/shared-component-library";
import {MenuList, Popover} from "@mui/material";
import {ShortcutCardRemoveDialog} from "./overview/ShortcutCardRemoveDialog";

export interface DotsVerticalProps {
	readonly reloadSource: boolean;
	readonly setLoadPage: React.Dispatch<React.SetStateAction<boolean>>;
	readonly setReloadSource: React.Dispatch<React.SetStateAction<boolean>>;
}

export function DotsVertical(props: DotsVerticalProps) {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	
	const reloadSource = props.reloadSource;
	const setLoadPage = props.setLoadPage;
	const setReloadSource = props.setReloadSource;
	
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	const open = Boolean(anchorEl);
	
	return <>
		<SbIconButton IconButtonProps={{
			onClick: handleClick
		}}>
			<DotsVerticalIcon width={20} />
		</SbIconButton>
		
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			slotProps={{
				paper: {
					style: {
						boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
					}
				}
			}}
		>
			<MenuList>
				<SbButton
					ButtonProps={{
						variant: "text",
						size: "small"
					}}
					onComplete={() => setOpenDialog(!openDialog)}
				>
					Remove default shortcuts
				</SbButton>
			</MenuList>
		</Popover>
		
		<ShortcutCardRemoveDialog
			onClose={() => setOpenDialog(!openDialog)}
			open={openDialog}
			reloadSource={reloadSource}
			setLoadPage={setLoadPage}
			setReloadSource={setReloadSource}
		/>
	</>;
}
