import {
	Alert,
	Dialog,
	DialogContent,
	DialogTitle, FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack
} from "@mui/material";
import {SbProcessButton, SbTypography, XCloseIcon} from "@surebase/shared-component-library";
import React, {useState} from "react";
import {ShortcutsDataRepository} from "../../../services/shortcuts/ShortcutsDataRepository";
import {Shortcuts} from "../../../services/shortcuts/Shortcuts";
import Shortcut = Shortcuts.Shortcut;

export interface ShortcutCardAddDialogProps {
	readonly onClose: () => void;
	readonly open: boolean;
	readonly reloadSource: boolean;
	readonly setLoadPage: React.Dispatch<React.SetStateAction<boolean>>;
	readonly setReloadSource: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ShortcutCardAddDialog(props: ShortcutCardAddDialogProps) {
	const [error, setError] = useState<string>();
	const [loadPage, setLoadPage] = useState<boolean>(true);
	
	const [defaultShortcuts, setDefaultShortcuts] = useState<Shortcut[]>([]);
	
	console.log(defaultShortcuts);
	
	const [userShortcutSetKrn, setUserShortcutSetKrn] = useState<string>("");
	const [userShortcuts, setUserShortcuts] = useState<Shortcut[]>([]);
	const [userShortcutSetExists, setUserShortcutSetExists] = useState<boolean>(false);
	
	const [selectedDefaultShortcut, setSelectedDefaultShortcut] = useState<string>("");
	
	const onClose = props.onClose;
	const open = props.open;
	const reloadSource = props.reloadSource;
	const setReloadSource = props.setReloadSource;
	
	if (loadPage || reloadSource) {
		setLoadPage(false);
		setReloadSource(false);
		
		ShortcutsDataRepository
			.retrieveDefaultShortcutSetForOwnerType()
			.then(payload => {
				const defaultShortcuts = payload?.defaultShortcuts ?? [];
				
				if (defaultShortcuts.length === 0)
					return;
				
				ShortcutsDataRepository
					.retrieveUserShortcutSet()
					.then(payload => {
						if (payload === null) {
							setUserShortcutSetExists(false);
							setDefaultShortcuts(defaultShortcuts.filter(s => !s.required && !s.disabled));
							
							return;
						}
						
						setUserShortcutSetExists(true);
						
						const userShortcutKrn = payload.krn;
						const userShortcuts = payload.userShortcuts ?? [];
						
						setUserShortcutSetKrn(userShortcutKrn);
						
						if (userShortcuts.length === 0) {
							setDefaultShortcuts(defaultShortcuts.filter(s => !s.required && !s.disabled));
							
							return;
						}
						
						setUserShortcuts(userShortcuts);
						
						const filteredDefaultShortcuts = defaultShortcuts.filter(defaultShortcut =>
							!defaultShortcut.required &&
							userShortcuts.find(userShortcut => defaultShortcut.name === userShortcut.name) === undefined
						);
						
						setDefaultShortcuts(filteredDefaultShortcuts);
					}).catch(() => {
						setError("Unable to retrieve user shortcut set.");
					});
			}).catch(() => {
				setError("Unable to retrieve default shortcut set.");
			});
	}
	
	async function updateUserShortcutSet() {
		const shortcutToAdd = defaultShortcuts.find(s => s.name === selectedDefaultShortcut);
		
		if (shortcutToAdd === undefined)
			return;
		
		userShortcuts.push(shortcutToAdd);
		
		const response = !userShortcutSetExists
			? await ShortcutsDataRepository.createUserShortcutSet({userShortcuts: userShortcuts})
			: await ShortcutsDataRepository.updateUserShortcutSet({krn: userShortcutSetKrn, userShortcuts: userShortcuts});
		
		if (response.errors != null && response.errors.length > 0)
			throw new Error(response.errors[0].message);
		
		props.setLoadPage(true);
		setLoadPage(true);
		setReloadSource(true);
		
		onClose();
	}
	
	return <Dialog
		fullWidth
		maxWidth="sm"
		open={open}
		onClose={onClose}
	>
		<DialogTitle>
			<SbTypography>
				Add a predefined default shortcut
			</SbTypography>
		</DialogTitle>
		
		<IconButton
			aria-label="close"
			onClick={onClose}
			sx={(theme) => ({
				position: "absolute",
				right: 8,
				top: 8,
				color: theme.palette.grey[500],
			})}
		>
			<XCloseIcon style={{color: "#000000"}}/>
		</IconButton>
		
		<DialogContent style={{minWidth: "400px"}}>
			<Stack gap={1}>
				<FormControl fullWidth>
					<InputLabel id="label">
						Make a choice:
					</InputLabel>
					<Select
						label={"Make a choice:"}
						onChange={event => setSelectedDefaultShortcut(event.target.value)}
						value={selectedDefaultShortcut}
						variant={"outlined"}
					>
						{
							defaultShortcuts.length === 0
								? <MenuItem key={"empty"} value={""} disabled={true}>- No shortcuts available -</MenuItem>
								: defaultShortcuts.map(shortcut =>
									<MenuItem
										key={shortcut.name}
										value={shortcut.name}
									>
										{shortcut.name}
									</MenuItem>
								)
						}
					</Select>
				</FormControl>
				
				{error &&
					<Alert severity={"error"}>
						<SbTypography>{error}</SbTypography>
					</Alert>
				}
				
				<SbProcessButton
					defaultContent={
						<SbTypography style={{
							fontWeight: "600",
							fontSize: "16px",
							lineHeight: "24px"
						}}>
							Add
						</SbTypography>
					}
					startIcon={<></>}
					LoadingButtonProps={{
						size: "large",
						variant: "contained",
						style: {
							borderRadius: "8px"
						},
						onClick: updateUserShortcutSet
					}}
				/>
			</Stack>
		</DialogContent>
	</Dialog>;
}
