import {Dossiers} from "../services/documents/Dossiers";
import {DossiersDataRepository} from "../services/documents/DossiersDataRepository";

export class DossierHelper {
	static mimeTypes: Record<string, string> = {
		"txt": "text/plain",
		"html": "text/html",
		"css": "text/css",
		"js": "application/javascript",
		"json": "application/json",
		"csv": "text/csv",
		"xml": "application/xml",
		"pdf": "application/pdf",
		"jpeg": "image/jpeg",
		"jpg": "image/jpeg",
		"png": "image/png",
		"gif": "image/gif",
		"bmp": "image/bmp",
		"svg": "image/svg+xml",
		"webp": "image/webp",
		"mp3": "audio/mpeg",
		"wav": "audio/wav",
		"ogg": "audio/ogg",
		"mp4": "video/mp4",
		"webm": "video/webm",
		"avi": "video/x-msvideo",
		"zip": "application/zip",
		"gz": "application/gzip",
		"rar": "application/vnd.rar",
		"7z": "application/x-7z-compressed",
		"woff": "font/woff",
		"woff2": "font/woff2",
		"ttf": "font/ttf",
		"otf": "font/otf"
	};
	
	static async downloadDossiers(dossierIds: string[]): Promise<(Dossiers.DownloadFile | null)[]> {
		const length = dossierIds.length;
		if (dossierIds.length === 0)
			return Promise.resolve([]);
		
		const promises = [];
		for (let i = 0; i < length; i++) {
			const dossierId = dossierIds[i];
			promises.push(DossiersDataRepository.getDownloadUrlForDossier(dossierId));
		}
		
		return await Promise.all(promises);
	}
	
	static parseResponseAndDownload(downloadFile: Dossiers.DownloadFile): void {
		const bytes = this.base64ToArrayBuffer(downloadFile.base64File);
		const extension = downloadFile.fileName.split(".").pop()?.toLowerCase() || "";
		const mimeType = this.mimeTypes[extension];
		if (mimeType) {
			const blob = new Blob([bytes], {type: mimeType});
			const url = URL.createObjectURL(blob);
			window.open(url, "_blank");
			URL.revokeObjectURL(url);
		} else {
			this.saveByteArray(downloadFile.fileName, bytes, downloadFile.contentType);
		}
	}
	
	static saveByteArray(reportName: string, byte: Uint8Array, contentType: string) {
		const blob = new Blob([byte], {type: contentType}); //"application/pdf"
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.download = reportName;
		link.click();
	}
	
	static base64ToArrayBuffer(base64: string) {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return bytes;
	}
}
