import { Stack } from "@mui/material";
import React from "react";
import {Shortcuts} from "../../../services/shortcuts/Shortcuts";
import { ShortcutCardDefaultAdd } from "./ShortcutCardDefaultAdd";
import {ShortcutCardDefaultEdit} from "./ShortcutCardDefaultEdit";

export interface ShortcutListDefaultProps {
	readonly ownerType: string;
	readonly setKrn?: string;
	readonly setLoadPage?: React.Dispatch<React.SetStateAction<boolean>>;
	readonly shortcuts?: Shortcuts.Shortcut[];
}

export function ShortcutListDefault(props: ShortcutListDefaultProps) {
	const ownerType = props.ownerType;
	const setKrn = props.setKrn ?? "";
	const setLoadPage = props.setLoadPage;
	const shortcuts = props.shortcuts;
	
	return <>
		<Stack
			style={{
				display: "grid",
				gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
				gap: "24px",
			}}
		>
			{
				shortcuts !== undefined &&
					shortcuts.map((shortcut, index) =>
						<ShortcutCardDefaultEdit
							key={index}
							setKrn={setKrn}
							setLoadPage={setLoadPage}
							shortcut={shortcut}
							shortcuts={shortcuts}
						/>
					)
			}
			
			<ShortcutCardDefaultAdd
				krn={setKrn}
				ownerType={ownerType}
				setLoadPage={setLoadPage}
				shortcuts={shortcuts}
			/>
		</Stack>
	</>;
}
