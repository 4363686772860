import React, {useState} from "react";
import {Shortcuts} from "../../../services/shortcuts/Shortcuts";
import {ShortcutsDataRepository} from "../../../services/shortcuts/ShortcutsDataRepository";
import {Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Stack} from "@mui/material";
import {SbDivider, SbProcessButton, SbTextField, SbTypography, XCloseIcon} from "@surebase/shared-component-library";
import {IconSelector} from "../IconSelector";

export interface ShortcutCardDefaultEditDialogProps {
	readonly krn: string;
	readonly onClose: () => void;
	readonly open: boolean;
	readonly setLoadPage?: React.Dispatch<React.SetStateAction<boolean>>;
	readonly shortcut: Shortcuts.Shortcut;
	readonly shortcuts: Shortcuts.Shortcut[];
}

export function ShortcutCardDefaultEditDialog(props: ShortcutCardDefaultEditDialogProps) {
	const krn = props.krn;
	const onClose = props.onClose;
	const open = props.open;
	const setLoadPage = props.setLoadPage;
	const shortcut = props.shortcut;
	const shortcuts = props.shortcuts;
	
	const [existingShortcutDisabledWasChanged, setExistingShortcutDisabledWasChanged] = useState<boolean>(false);
	
	const [existingShortcut, setExistingShortcut] = useState<Shortcuts.Shortcut>({
		description: shortcut.description,
		displayName: shortcut.displayName,
		icon: shortcut.icon,
		name: shortcut.name,
		type: "NAVIGATION",
		url: shortcut.url,
		disabled: shortcut.disabled,
		required: shortcut.required
	});
	
	async function updateDefaultShortcutSet() {
		const updatedDefaultShortcuts = shortcuts.map(shortcut =>
			shortcut.name === existingShortcut.name
				? existingShortcut
				: shortcut
		);
		
		const response = await ShortcutsDataRepository.updateDefaultShortcutSet({
			krn: krn,
			defaultShortcuts: updatedDefaultShortcuts
		});
		
		if (response.errors != null && response.errors.length > 0)
			throw new Error(response.errors[0].message);
		
		if (existingShortcutDisabledWasChanged)
			await ShortcutsDataRepository.enableOrDisableShortcutForUsers({
				shortcutName: existingShortcut.name,
				disable: existingShortcut.disabled,
			});
		
		if (setLoadPage)
			setLoadPage(true);
		
		onClose();
	}
	
	async function removeDefaultShortcutFromSet() {
		const updatedDefaultShortcuts = shortcuts.filter(shortcut =>
			shortcut.name !== existingShortcut.name
		);
		
		const response = await ShortcutsDataRepository.updateDefaultShortcutSet({
			krn: krn,
			defaultShortcuts: updatedDefaultShortcuts
		});
		
		if (response.errors != null && response.errors.length > 0)
			throw new Error(response.errors[0].message);
		
		if (setLoadPage)
			setLoadPage(true);
		
		onClose();
	}
	
	return <Dialog
		fullWidth
		maxWidth="sm"
		open={open}
		onClose={onClose}
	>
		<DialogTitle>
			<SbTypography>
				Edit default-shortcut
			</SbTypography>
		</DialogTitle>
		
		<IconButton
			aria-label="close"
			onClick={onClose}
			sx={(theme) => ({
				position: "absolute",
				right: 8,
				top: 8,
				color: theme.palette.grey[500],
			})}
		>
			<XCloseIcon style={{color: "#000000"}}/>
		</IconButton>
		
		<DialogContent
			style={{minWidth: "400px"}}
		>
			<Stack gap={1}>
				<SbTextField
					autoFocus
					label={<SbTypography>Description</SbTypography>}
					name={"description"}
					onChange={value => setExistingShortcut(shortcut => ({
						...shortcut,
						description: value !== "" ? value : null
					}))}
					type={"text"}
					value={existingShortcut.description ?? undefined}
					variant={"outlined"}
				/>
				
				<SbTextField
					label={<SbTypography>Display name</SbTypography>}
					name={"displayName"}
					onChange={value => setExistingShortcut(shortcut => ({
						...shortcut,
						displayName: value !== "" ? value : null
					}))}
					type={"text"}
					value={existingShortcut.displayName ?? undefined}
					variant={"outlined"}
				/>
				
				<IconSelector
					onChange={value => setExistingShortcut(shortcut => ({
						...shortcut,
						icon: value !== "" ? value : null
					}))}
					value={existingShortcut.icon ?? undefined}
				/>
				
				<SbTextField
					label={<SbTypography>Url</SbTypography>}
					name={"url"}
					onChange={value => setExistingShortcut(shortcut => ({
						...shortcut,
						url: value !== "" ? value : null
					}))}
					type={"text"}
					value={existingShortcut.url ?? undefined}
					variant={"outlined"}
				/>
				
				<FormControlLabel
					control={
						<Checkbox
							onChange={event => {
								const checked = event.target.checked;
								
								setExistingShortcutDisabledWasChanged(checked !== existingShortcut.disabled);
								setExistingShortcut(shortcut => ({
									...shortcut,
									disabled: checked
								}));
							}}
							checked={existingShortcut.disabled}
						/>
					}
					label={
						<SbTypography>
							Disabled
						</SbTypography>
					}
				/>
				
				<FormControlLabel
					control={
						<Checkbox
							onChange={event => setExistingShortcut(shortcut => ({
								...shortcut,
								required: event.target.checked
							}))}
							checked={existingShortcut.required}
						/>
					}
					label={
						<SbTypography>
							Required
						</SbTypography>
					}
				/>
				
				<SbProcessButton
					defaultContent={
						<SbTypography style={{
							fontWeight: "600",
							fontSize: "16px",
							lineHeight: "24px"
						}}>
							Update
						</SbTypography>
					}
					startIcon={<></>}
					LoadingButtonProps={{
						size: "large",
						variant: "contained",
						style: {
							borderRadius: "8px"
						},
						onClick: updateDefaultShortcutSet
					}}
				/>
				
				<SbDivider color={"lightgrey"} size={"1px"} borderRadius={"4px"}>
					<SbTypography style={{
						fontWeight: "400",
						fontSize: "14px",
						lineHeight: "20.02px",
						letterSpacing: "0.17px",
						color: "#6C737F"
					}}>
						or
					</SbTypography>
				</SbDivider>
				
				<SbProcessButton
					defaultContent={
						<SbTypography style={{
							fontWeight: "600",
							fontSize: "16px",
							lineHeight: "24px"
						}}>
							Delete
						</SbTypography>
					}
					startIcon={<></>}
					LoadingButtonProps={{
						size: "large",
						variant: "contained",
						style: {
							borderRadius: "8px"
						},
						onClick: removeDefaultShortcutFromSet
					}}
				/>
			</Stack>
		</DialogContent>
	</Dialog>;
}
