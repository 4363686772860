import React, {useState} from "react";
import {Shortcuts} from "../../../services/shortcuts/Shortcuts";
import {authorizedByPermission, SbFavoriteMenu, SbTypography} from "@surebase/shared-component-library";
import {ShortcutsDataRepository} from "../../../services/shortcuts/ShortcutsDataRepository";
import {Stack} from "@mui/material";
import {ShortcutListDefault} from "./ShortcutListDefault";
import { permissions } from "../../../global/Permissions";

export const ShortcutsDefaultOverview = authorizedByPermission(() => {
	const [loadPage, setLoadPage] = useState<boolean>(true);
	const [defaultShortcutSets, setDefaultShortcutSets] = useState<Shortcuts.DefaultShortcutSet[]>([]);
	
	const shortcutOwnerTypes = ["TENANT", "BROKER"];
	
	async function retrieveDefaultShortcutSets() {
		const response = await ShortcutsDataRepository.retrieveDefaultShortcutSets();
		
		setDefaultShortcutSets(response ?? []);
	}
	
	if (loadPage) {
		setLoadPage(false);
		
		retrieveDefaultShortcutSets().then();
	}
	
	return (
		<Stack gap={2}>
			<Stack gap={1}>
				<Stack
					direction={"row"}
					justifyContent={"space-between"}
				>
					<SbTypography variant={"cardButtonHeader2"}>
						Default shortcut sets
					</SbTypography>
					
					<SbFavoriteMenu
						description={"Redirect to the shortcuts page."}
						displayName={null}
						icon={"ArrowSquareRightIcon"}
						pageName={"Shortcuts"}
						url={"/shortcuts"}
					/>
				</Stack>
				
				{
					shortcutOwnerTypes.map((type, index) => {
						const defaultShortcutSet = defaultShortcutSets.find(set => set.applicableToOwnerType === type);
						
						return <Stack
							key={index}
							gap={1}
						>
							<SbTypography
								variant={"cardButtonContent2"}
							>
								{defaultShortcutSet?.name ?? type}
							</SbTypography>
							
							<ShortcutListDefault
								key={index}
								ownerType={type}
								setKrn={defaultShortcutSet?.krn}
								setLoadPage={setLoadPage}
								shortcuts={defaultShortcutSet?.defaultShortcuts}
							/>
						</Stack>;
					})
				}
			</Stack>
		</Stack>
	);
}, [permissions.defaultShortcutsCreate]);
