import React, {CSSProperties} from "react";
import {Shortcuts} from "../../../services/shortcuts/Shortcuts";
import {Card} from "@mui/material";
import {fullSize, SbLinkButton, SbTypographyRaw} from "@surebase/shared-component-library";
import { ShortcutIcon } from "../ShortcutIcon";

const minWidth = "388px";
const maxWidth = "450px";
const height = "112px";

const baseStyle: CSSProperties = {
	borderRadius: "8px",
	minWidth,
	maxWidth,
	height,
};

export interface ShortcutCardProps {
	readonly shortcut: Shortcuts.Shortcut;
}

export function ShortcutCard(props: ShortcutCardProps) {
	const shortcut = props.shortcut;
	const type = shortcut.type;
	
	switch(type) {
		case "NAVIGATION":
			return <NavigationCard shortcut={shortcut} />;
	}
	
	return <>Shortcut type not implemented.</>;
}

interface NavigationCardProps {
	readonly shortcut: Shortcuts.Shortcut;
}

function NavigationCard(props: NavigationCardProps) {
	const shortcut = props.shortcut;
	
	return <Card
		variant={"outlined"}
		style={baseStyle}
	>
		<SbLinkButton
			to={shortcut.url ?? ""}
			ButtonProps={{
				style: {
					...fullSize,
					borderRadius: "inherit",
					padding: "12px 24px",
					gap: "8px",
					justifyContent: "center",
					flexDirection: "column"
				}
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center"
				}}
			>
				<ShortcutIcon data={shortcut} />
			</div>
			
			<SbTypographyRaw
				variant={"cardButtonHeader2"}
				color={theme => theme.palette.text.primary}
			>
				{shortcut.displayName ?? shortcut.name}
			</SbTypographyRaw>
			
			<SbTypographyRaw
				variant={"cardButtonContent2"}
				color={theme => theme.palette.text.primary}
			>
				{shortcut.description}
			</SbTypographyRaw>
		</SbLinkButton>
	</Card>;
}
