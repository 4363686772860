export namespace Shortcuts {
	export interface Queries {
		readonly defaultShortcutSets: DefaultShortcutSetsConnection | null;
		readonly allShortcuts: GetAllShortcutsPayload;
		readonly defaultShortcutSetForOwnerType: DefaultShortcutSet | null;
		readonly userShortcutSet: UserShortcutSet | null;
		readonly checkUserShortcutsForFavoritedPage: CheckUserShortcutsForFavoritedPagePayload;
		readonly createDefaultShortcutSet: CreateDefaultShortcutSetPayload;
		readonly createUserShortcutSet: CreateUserShortcutSetPayload;
		readonly updateDefaultShortcutSet: UpdateDefaultShortcutSetPayload;
		readonly updateUserShortcutSet: UpdateUserShortcutSetPayload;
		readonly enableOrDisableShortcutForUsers: EnableOrDisableShortcutForUsersPayload;
	}
	
	export interface Error {
		readonly message: string;
	}
	
	export interface IGraphQlErrorBase {
		readonly type: string;
		readonly message: string;
	}
	
	export interface CheckUserShortcutsForFavoritedPagePayload {
		readonly isPageFavorited: boolean;
	}
	
	export interface CreateDefaultShortcutSetPayload {
		readonly defaultShortcutSet: DefaultShortcutSet | null;
		readonly errors: CreateDefaultShortcutSetError[] | null;
	}
	
	export interface CreateUserShortcutSetPayload {
		readonly userShortcutSet: UserShortcutSet | null;
		readonly errors: CreateUserShortcutSetError[] | null;
	}
	
	export interface DefaultShortcutSet {
		readonly krn: string;
		readonly name: string;
		readonly applicableToOwnerType: SureBaseResourceOwnerType;
		readonly defaultShortcuts: Shortcut[];
		readonly id: string;
		readonly version: number | null;
		readonly creationDateTime: string | null;
		readonly lastModifiedDateTime: string | null;
		readonly lastModifiedBy: string | null;
	}
	
	// A connection to a list of items.
	export interface DefaultShortcutSetsConnection {
		// Information to aid in pagination.
		readonly pageInfo: PageInfo;
		// A list of edges.
		readonly edges: DefaultShortcutSetsEdge[] | null;
		// A flattened list of the nodes.
		readonly nodes: DefaultShortcutSet[] | null;
	}
	
	// An edge in a connection.
	export interface DefaultShortcutSetsEdge {
		// A cursor for use in pagination.
		readonly cursor: string;
		// The item at the end of the edge.
		readonly node: DefaultShortcutSet;
	}
	
	export interface DocumentNotFoundError {
		readonly message: string;
		readonly documentId: string;
		readonly type: string;
	}
	
	export interface EnableOrDisableShortcutForUsersPayload {
		readonly errors: EnableOrDisableShortcutForUsersError[] | null;
	}
	
	export interface GetAllShortcutsPayload {
		readonly shortcuts: Shortcut[];
	}
	
	export interface InvalidInputError {
		readonly message: string;
		readonly fieldName: string;
		readonly reason: string;
		readonly type: string;
	}
	
	export interface NotAuthenticatedError {
		readonly message: string;
		readonly reason: string;
		readonly type: string;
	}
	
	export interface NotAuthorizedError {
		readonly message: string;
		readonly user: string;
		readonly action: string;
		readonly resource: string;
		readonly reason: string;
		readonly type: string;
	}
	
	// Information about pagination in a connection.
	export interface PageInfo {
		// Indicates whether more edges exist following the set defined by the clients arguments.
		readonly hasNextPage: boolean;
		// Indicates whether more edges exist prior the set defined by the clients arguments.
		readonly hasPreviousPage: boolean;
		// When paginating backwards, the cursor to continue.
		readonly startCursor: string | null;
		// When paginating forwards, the cursor to continue.
		readonly endCursor: string | null;
	}
	
	export interface Shortcut {
		readonly description: string | null;
		readonly displayName: string | null;
		readonly icon: string | null;
		readonly name: string;
		readonly type: ShortcutType | null;
		readonly url: string | null;
		readonly disabled: boolean;
		readonly required: boolean;
	}
	
	export interface UpdateDefaultShortcutSetPayload {
		readonly defaultShortcutSet: DefaultShortcutSet | null;
		readonly errors: UpdateDefaultShortcutSetError[] | null;
	}
	
	export interface UpdateUserShortcutSetPayload {
		readonly userShortcutSet: UserShortcutSet | null;
		readonly errors: UpdateUserShortcutSetError[] | null;
	}
	
	export interface UserShortcutSet {
		readonly krn: string;
		readonly userKrn: string;
		readonly userShortcuts: Shortcut[];
		readonly id: string;
		readonly version: number | null;
		readonly creationDateTime: string | null;
		readonly lastModifiedDateTime: string | null;
		readonly lastModifiedBy: string | null;
	}
	
	export type CreateDefaultShortcutSetError = InvalidInputError | NotAuthenticatedError | NotAuthorizedError;
	
	export type CreateUserShortcutSetError = InvalidInputError | NotAuthenticatedError | NotAuthorizedError;
	
	export type EnableOrDisableShortcutForUsersError = InvalidInputError | NotAuthenticatedError | NotAuthorizedError;
	
	export type UpdateDefaultShortcutSetError = InvalidInputError | NotAuthenticatedError | NotAuthorizedError | DocumentNotFoundError;
	
	export type UpdateUserShortcutSetError = InvalidInputError | NotAuthenticatedError | NotAuthorizedError | DocumentNotFoundError;
	
	export interface BooleanOperationFilterInput {
		readonly eq?: boolean | null;
		readonly neq?: boolean | null;
	}
	
	export interface CheckUserShortcutsForFavoritedPageInput {
		readonly pageName: string;
	}
	
	export interface CreateDefaultShortcutSetInput {
		readonly name: string;
		readonly applicableToOwnerType: SureBaseResourceOwnerType;
		readonly defaultShortcuts: ShortcutInput[];
	}
	
	export interface CreateUserShortcutSetInput {
		readonly userShortcuts: ShortcutInput[];
	}
	
	export interface DateTimeOperationFilterInput {
		readonly eq?: string | null;
		readonly neq?: string | null;
		readonly in?: (string | null)[] | null;
		readonly nin?: (string | null)[] | null;
		readonly gt?: string | null;
		readonly ngt?: string | null;
		readonly gte?: string | null;
		readonly ngte?: string | null;
		readonly lt?: string | null;
		readonly nlt?: string | null;
		readonly lte?: string | null;
		readonly nlte?: string | null;
	}
	
	export interface DefaultShortcutSetFilterInput {
		readonly and?: DefaultShortcutSetFilterInput[] | null;
		readonly or?: DefaultShortcutSetFilterInput[] | null;
		readonly krn?: StringOperationFilterInput | null;
		readonly name?: StringOperationFilterInput | null;
		readonly applicableToOwnerType?: SureBaseResourceOwnerTypeOperationFilterInput | null;
		readonly defaultShortcuts?: ListFilterInputTypeOfShortcutFilterInput | null;
		readonly id?: UuidOperationFilterInput | null;
		readonly version?: IntOperationFilterInput | null;
		readonly creationDateTime?: DateTimeOperationFilterInput | null;
		readonly lastModifiedDateTime?: DateTimeOperationFilterInput | null;
		readonly lastModifiedBy?: StringOperationFilterInput | null;
	}
	
	export interface DefaultShortcutSetSortInput {
		readonly krn?: SortEnumType | null;
		readonly name?: SortEnumType | null;
		readonly applicableToOwnerType?: SortEnumType | null;
		readonly id?: SortEnumType | null;
		readonly version?: SortEnumType | null;
		readonly creationDateTime?: SortEnumType | null;
		readonly lastModifiedDateTime?: SortEnumType | null;
		readonly lastModifiedBy?: SortEnumType | null;
	}
	
	export interface EnableOrDisableShortcutForUsersInput {
		readonly shortcutName: string;
		readonly disable: boolean;
	}
	
	export interface IntOperationFilterInput {
		readonly eq?: number | null;
		readonly neq?: number | null;
		readonly in?: (number | null)[] | null;
		readonly nin?: (number | null)[] | null;
		readonly gt?: number | null;
		readonly ngt?: number | null;
		readonly gte?: number | null;
		readonly ngte?: number | null;
		readonly lt?: number | null;
		readonly nlt?: number | null;
		readonly lte?: number | null;
		readonly nlte?: number | null;
	}
	
	export interface ListFilterInputTypeOfShortcutFilterInput {
		readonly all?: ShortcutFilterInput | null;
		readonly none?: ShortcutFilterInput | null;
		readonly some?: ShortcutFilterInput | null;
		readonly any?: boolean | null;
	}
	
	export interface NullableOfShortcutTypeOperationFilterInput {
		readonly eq?: ShortcutType | null;
		readonly neq?: ShortcutType | null;
		readonly in?: (ShortcutType | null)[] | null;
		readonly nin?: (ShortcutType | null)[] | null;
	}
	
	export interface ShortcutFilterInput {
		readonly and?: ShortcutFilterInput[] | null;
		readonly or?: ShortcutFilterInput[] | null;
		readonly description?: StringOperationFilterInput | null;
		readonly displayName?: StringOperationFilterInput | null;
		readonly icon?: StringOperationFilterInput | null;
		readonly name?: StringOperationFilterInput | null;
		readonly type?: NullableOfShortcutTypeOperationFilterInput | null;
		readonly url?: StringOperationFilterInput | null;
		readonly disabled?: BooleanOperationFilterInput | null;
		readonly required?: BooleanOperationFilterInput | null;
	}
	
	export interface ShortcutInput {
		readonly description?: string | null;
		readonly displayName?: string | null;
		readonly icon?: string | null;
		readonly name: string;
		readonly type?: ShortcutType | null;
		readonly url?: string | null;
		readonly disabled?: boolean; // default value: false
		readonly required?: boolean; // default value: false
	}
	
	export interface StringOperationFilterInput {
		readonly and?: StringOperationFilterInput[] | null;
		readonly or?: StringOperationFilterInput[] | null;
		readonly eq?: string | null;
		readonly neq?: string | null;
		readonly contains?: string | null;
		readonly ncontains?: string | null;
		readonly in?: (string | null)[] | null;
		readonly nin?: (string | null)[] | null;
		readonly startsWith?: string | null;
		readonly nstartsWith?: string | null;
		readonly endsWith?: string | null;
		readonly nendsWith?: string | null;
	}
	
	export interface SureBaseResourceOwnerTypeOperationFilterInput {
		readonly eq?: SureBaseResourceOwnerType | null;
		readonly neq?: SureBaseResourceOwnerType | null;
		readonly in?: SureBaseResourceOwnerType[] | null;
		readonly nin?: SureBaseResourceOwnerType[] | null;
	}
	
	export interface UpdateDefaultShortcutSetInput {
		readonly krn: string;
		readonly defaultShortcuts: ShortcutInput[];
	}
	
	export interface UpdateUserShortcutSetInput {
		readonly krn: string;
		readonly userShortcuts: ShortcutInput[];
	}
	
	export interface UuidOperationFilterInput {
		readonly eq?: string | null;
		readonly neq?: string | null;
		readonly in?: (string | null)[] | null;
		readonly nin?: (string | null)[] | null;
		readonly gt?: string | null;
		readonly ngt?: string | null;
		readonly gte?: string | null;
		readonly ngte?: string | null;
		readonly lt?: string | null;
		readonly nlt?: string | null;
		readonly lte?: string | null;
		readonly nlte?: string | null;
	}
	
	export const applyPolicy = [
		"BEFORE_RESOLVER",
		"AFTER_RESOLVER",
		"VALIDATION",
	] as const;
	export type ApplyPolicy = typeof applyPolicy[number];
	
	export const shortcutType = [
		"NAVIGATION",
	] as const;
	export type ShortcutType = typeof shortcutType[number];
	
	export const sortEnumType = [
		"ASC",
		"DESC",
	] as const;
	export type SortEnumType = typeof sortEnumType[number];
	
	export const sureBaseResourceOwnerType = [
		"PLATFORM",
		"TENANT",
		"BROKER",
		"USER",
	] as const;
	export type SureBaseResourceOwnerType = typeof sureBaseResourceOwnerType[number];
}
